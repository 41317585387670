import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import {
  $getSelection,
  $isNodeSelection,
  CLICK_COMMAND,
  SELECTION_CHANGE_COMMAND,
  COMMAND_PRIORITY_LOW,
  $getNodeByKey,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { $isImageNode } from './nodes/ImageNode';
import ImageResizer from './ImageResizer';

export default function ImageComponent({
  src,
  altText,
  nodeKey,
  width,
  height,
  maxWidth,
  editor,
}) {
  const imageRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isSelected, setSelected, clearSelection] =
    useLexicalNodeSelection(nodeKey);

  const $onDelete = useCallback(
    (payload) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        const event = payload;
        event.preventDefault();
        const node = $getNodeByKey(nodeKey);
        if ($isImageNode(node)) {
          node.remove();
          return true;
        }
      }
      return false;
    },
    [isSelected, nodeKey]
  );

  const onClick = useCallback(
    (payload) => {
      const event = payload;

      if (isResizing) {
        return true;
      }
      if (event.target === imageRef.current) {
        if (event.shiftKey) {
          setSelected(!isSelected);
        } else {
          clearSelection();
          setSelected(true);
        }
        setIsFocused(true);
        return true;
      }

      return false;
    },
    [isResizing, isSelected, setSelected, clearSelection]
  );

  const onResizeEnd = (nextWidth, nextHeight) => {
    setTimeout(() => {
      setIsResizing(false);
    }, 200);

    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isImageNode(node)) {
        node.setWidthAndHeight(nextWidth, nextHeight);
        node.markDirty();
      }
    });
  };

  const onResizeStart = () => {
    setIsResizing(true);
  };

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerCommand(
        CLICK_COMMAND,
        (event) => {
          return onClick(event);
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          const selection = editor.getEditorState().read(() => $getSelection());
          if (!$isNodeSelection(selection) || !selection.has(nodeKey)) {
            setIsFocused(false);
          }
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_DELETE_COMMAND,
        $onDelete,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        $onDelete,
        COMMAND_PRIORITY_LOW
      )
    );

    return () => {
      unregister();
    };
  }, [
    clearSelection,
    editor,
    nodeKey,
    onClick,
    $onDelete,
    onClick,
    setSelected,
    isSelected,
  ]);

  useEffect(() => {
    if (isSelected && imageRef.current) {
      imageRef.current.focus();
    }
  }, [isSelected]);

  return (
    <div className={`editor-image ${isFocused ? 'focused' : ''}`} tabIndex={-1}>
      <img
        ref={imageRef}
        src={src}
        alt={altText}
        className={isSelected ? 'focused' : ''}
        style={{
          maxWidth: '100%',
          width: width,
          height: height,
          display: 'block',
          margin: '0',
        }}
      />
      {$isNodeSelection(editor.getEditorState().read(() => $getSelection())) &&
        isFocused && (
          <ImageResizer
            imageRef={imageRef}
            editor={editor}
            onResizeStart={onResizeStart}
            onResizeEnd={onResizeEnd}
          />
        )}
    </div>
  );
}
