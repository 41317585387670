import {
  createCommand,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  PASTE_COMMAND,
  $insertNodes,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createImageNode, ImageNode } from '../nodes/ImageNode';
import { mergeRegister } from '@lexical/utils';
import { useEffect } from 'react';

const INSERT_IMAGE_COMMAND = createCommand('INSERT_IMAGE_COMMAND');

export default function ImagesPlugin() {
  const [editor] = useLexicalComposerContext();

  const handlePaste = (event, editor) => {
    const items = event.clipboardData?.items;
    if (!items) return;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.startsWith('image/')) {
        const file = item.getAsFile();
        if (!file) continue;
        const reader = new FileReader();
        reader.onload = function (loadEvent) {
          const base64Image = loadEvent.target.result;
          editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
            src: base64Image,
            altText: 'Pasted Image',
          });
        };
        reader.readAsDataURL(file);
        event.preventDefault();
        break;
      }
    }
  };

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagesPlugin: ImageNode not registered on editor');
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          const { src, altText } = payload;
          const imageNode = $createImageNode({ src, altText });
          $insertNodes([imageNode]);
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        PASTE_COMMAND,
        (event) => handlePaste(event, editor),
        COMMAND_PRIORITY_HIGH
      )
    );
  }, [editor]);

  return null;
}
