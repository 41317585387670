import React, { useEffect } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import axiosInstance from '../utils/axiosConfig';
import { usePrograms, useDeleteProgram } from './hooks/usePrograms';

const Programs = () => {
  const location = useLocation();
  const { data: programs, error, isLoading } = usePrograms();
  const deleteProgramMutation = useDeleteProgram();

  const isEditing =
    location.pathname.includes('/dashboard/programs/edit') ||
    location.pathname.includes('/dashboard/programs/new');

  const handleDeleteProgram = async (programId) => {
    deleteProgramMutation.mutate(programId);
  };

  if (isLoading) {
    return (
      <div className="flex-grow bg-cream pt-6 px-10 pb-0">
        <div className="flex items-center mb-4">
          <h1 className="font-recoleta-alt text-charcoal text-3xl font-medium leading-10 mr-2">
            Loading...
          </h1>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex-grow bg-cream pt-6 px-10 pb-0">
        <div className="flex items-center mb-4">
          <h1 className="font-recoleta-alt text-charcoal text-3xl font-medium leading-10 mr-2">
            Error fetching programs: {error.message}
          </h1>
        </div>
      </div>
    );
  }

  if (isEditing) {
    return <Outlet />;
  }

  return (
    <div className="flex-grow bg-cream pt-6 px-10 pb-0">
      <div className="flex items-center mb-4">
        <h1 className="font-recoleta-alt text-charcoal text-3xl font-medium leading-10 mr-2">
          Programs
        </h1>
        <NavLink
          to="/dashboard/programs/new"
          className="flex items-center text-greenroot font-dm-sans py-2 px-4"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add Program
        </NavLink>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {programs.map((program) => (
          <ProgramCard
            id={program.id}
            key={program.id}
            title={program.name}
            currentEnrolled={`${program.current_enrolled} students currently enrolled`}
            totalEnrolled={`${program.total_enrolled} students across all time`}
            certificatesIssued={`${program.certificates_issued} certificates issued`}
            onDelete={handleDeleteProgram}
          />
        ))}
      </div>
    </div>
  );
};

const ProgramCard = ({
  id,
  title,
  currentEnrolled,
  totalEnrolled,
  certificatesIssued,
  onDelete,
}) => {
  const navigate = useNavigate();

  const handleDelete = () => {
    if (window.confirm(`Are you sure you want to delete the program "${title}"?`)) {
      onDelete(id);
    }
  };

  const handleEdit = () => {
    navigate(`/dashboard/programs/edit/${id}`);
  };

  return (
    <div className="bg-white border border-charcoal rounded-lg p-0">
      <div className="flex bg-beige border-b border-charcoal justify-between rounded-t-lg p-2 px-4">
        <h2 className="font-recoleta-alt text-charcoal text-xl font-medium leading-6 mb-0">
          {title}
        </h2>
        <div className="flex space-x-2">
          <button onClick={handleEdit} className="text-charcoal text-sm">
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <button onClick={handleDelete} className="text-charcoal text-sm">
            <FontAwesomeIcon icon={faTrashCan} />
          </button>
        </div>
      </div>
      <div className="flex flex-col p-4">
        <div className="text-gray-600 mb-2 font-dm-sans text-charcoal text-sm">
          {currentEnrolled}
        </div>
        <div className="flex justify-between items-center">
          <div className="text-gray-600 font-dm-sans text-charcoal text-xs">{totalEnrolled}</div>
          <div className="text-orange-500 font-dm-sans text-charcoal text-xs">
            {certificatesIssued}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Programs;
