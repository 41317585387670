import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, NavLink, useParams, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faPlus,
  faPencilAlt,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { useProgramDetails, useSaveProgram } from './hooks/usePrograms';
import {
  useProgramModules,
  useDeleteModule,
  useSaveModule,
} from './hooks/useProgramModules';

const EditProgram = () => {
  const [programName, setProgramName] = useState('');
  const [programDescription, setProgramDescription] = useState('');
  const [instructorDescription, setInstructorDescription] = useState('');
  const navigate = useNavigate();
  const { programId } = useParams();

  const isEditingModule =
    location.pathname.includes('modules/edit') ||
    location.pathname.includes('modules/new');

  const { data: program, isSuccess: isProgramLoaded } =
    useProgramDetails(programId);
  const { data: modules = [], isSuccess: isModulesLoaded } =
    useProgramModules(programId);

  const saveProgramMutation = useSaveProgram();
  const saveModuleMutation = useSaveModule(programId);
  const deleteModuleMutation = useDeleteModule(programId);

  const programDescriptionRef = useRef(null);
  const instructorDescriptionRef = useRef(null);

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight(programDescriptionRef.current);
    adjustTextareaHeight(instructorDescriptionRef.current);
  }, [programDescription, instructorDescription]);

  useEffect(() => {
    if (isProgramLoaded && program) {
      setProgramName(program.name);
      setProgramDescription(program.description);
      setInstructorDescription(program.instructor_description);
    }
  }, [program, isProgramLoaded]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const programData = {
      name: programName,
      description: programDescription,
      instructor_description: instructorDescription,
    };

    try {
      saveProgramMutation.mutate(
        { programId, programData },
        {
          onMutate: async ({ programId, programData }) => {
            await saveProgramMutation.queryClient.cancelQueries([
              'programs',
              programId,
            ]);

            const previousProgram =
              saveProgramMutation.queryClient.getQueryData([
                'programs',
                programId,
              ]);

            saveProgramMutation.queryClient.setQueryData(
              ['programs', programId],
              (old) => ({
                ...old,
                ...programData,
              })
            );

            return { previousProgram };
          },
          onError: (err, newProgram, context) => {
            saveProgramMutation.queryClient.setQueryData(
              ['programs', programId],
              context.previousProgram
            );
          },
          onSettled: () => {
            saveProgramMutation.queryClient.invalidateQueries([
              'programs',
              programId,
            ]);
          },
        }
      );

      navigate('/dashboard/programs');
    } catch (error) {
      console.error('There was an error saving the program:', error);
    }
  };

  const handleCreateModule = () => {
    navigate(`/dashboard/programs/edit/${programId}/modules/new`);
  };

  const handleEditModule = (moduleId) => {
    navigate(`/dashboard/programs/edit/${programId}/modules/edit/${moduleId}`);
  };

  const handleDeleteModule = async (moduleId) => {
    try {
      deleteModuleMutation.mutate(moduleId, {
        onMutate: async (moduleId) => {
          await deleteModuleMutation.queryClient.cancelQueries([
            'programModules',
            programId,
          ]);

          const previousModules = deleteModuleMutation.queryClient.getQueryData(
            ['programModules', programId]
          );

          deleteModuleMutation.queryClient.setQueryData(
            ['programModules', programId],
            (old) => old.filter((module) => module.id !== moduleId)
          );

          return { previousModules };
        },
        onError: (err, moduleId, context) => {
          deleteModuleMutation.queryClient.setQueryData(
            ['programModules', programId],
            context.previousModules
          );
        },
        onSettled: () => {
          deleteModuleMutation.queryClient.invalidateQueries([
            'programModules',
            programId,
          ]);
        },
      });
    } catch (error) {
      console.error('Error deleting module:', error);
    }
  };

  if (isEditingModule) {
    return <Outlet />;
  }

  return (
    <form onSubmit={handleSubmit} className="bg-cream min-h-full">
      <div>
        <div className="mb-6 px-8 border-b-2 border-charcoal">
          <div className="flex justify-between items-center py-4">
            <div className="flex items-center">
              <NavLink
                to="/dashboard/programs"
                className="text-charcoal mr-2 text-xs"
              >
                <FontAwesomeIcon icon={faChevronLeft} size="lg" />
              </NavLink>
              <h1 className="font-recoleta-alt text-charcoal text-3xl font-medium leading-10 ml-2">
                {programId ? 'Edit Program' : 'Create Program'}
              </h1>
            </div>
            <button
              type="submit"
              className="bg-greenroot text-beige font-bold py-2 px-4 rounded-full font-dm-sans text-sm w-133"
            >
              Save
            </button>
          </div>
        </div>
        <div className="px-8">
          <div className="relative mb-4">
            <input
              type="text"
              id="program_name"
              value={programName}
              onChange={(e) => setProgramName(e.target.value)}
              className="block px-3 py-3 w-full text-sm bg-cream rounded-lg border border-charcoal appearance-none dark:text-white focus:outline-none focus:ring-0 focus:border-blue-500 peer text-charcoal font-dm-sans"
              placeholder=" "
            />
            <label
              htmlFor="program_name"
              className="absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] bg-cream px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-6 text-charcoal font-dm-sans left-2"
            >
              Program Name
            </label>
          </div>
          <div className="relative mb-4">
            <textarea
              id="program_description"
              value={programDescription}
              onChange={(e) => {
                setProgramDescription(e.target.value);
                adjustTextareaHeight(e.target);
              }}
              className="min-h-11 resize-y block px-3 py-3 w-full text-sm bg-cream rounded-lg border border-charcoal appearance-none dark:text-white focus:outline-none focus:ring-0 focus:border-blue-500 peer text-charcoal font-dm-sans"
              placeholder=" "
              rows="1"
            ></textarea>
            <label
              htmlFor="program_description"
              className="absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] bg-cream px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-6 text-charcoal font-dm-sans left-2"
            >
              Program Description
            </label>
          </div>

          <div className="relative mb-4">
            <textarea
              id="instructor_description"
              value={instructorDescription}
              onChange={(e) => {
                setInstructorDescription(e.target.value);
                adjustTextareaHeight(e.target);
              }}
              className="min-h-11 resize-y block px-3 py-3 w-full text-sm bg-cream rounded-lg border border-charcoal appearance-none dark:text-white focus:outline-none focus:ring-0 focus:border-blue-500 peer text-charcoal font-dm-sans"
              placeholder=" "
              rows="1"
            ></textarea>
            <label
              htmlFor="instructor_description"
              className="absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] bg-cream px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-6 text-charcoal font-dm-sans left-2"
            >
              Instructor Description
            </label>
          </div>

          {programId && (
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <h2 className="text-charcoal text-center font-dm-sans text-sm font-bold leading-6 font-bold uppercase">
                  {modules?.length || 0} Modules
                </h2>
                <button
                  type="button"
                  onClick={handleCreateModule}
                  className="flex items-center text-greenroot font-dm-sans py-2 px-4 bg-transparent"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-1" />
                  Create module
                </button>
              </div>
              {modules?.length === 0 ? (
                <div className="flex flex-col justify-center items-center gap-4 p-6 border border-charcoal rounded-lg bg-beige w-full text-charcoal text-lg font-recoleta-alt">
                  No modules in this program yet
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center w-full">
                  {modules.map((module) => (
                    <div
                      key={module.id}
                      className="flex justify-between items-center w-full gap-4 px-6 py-4 border-4 border-charcoal rounded-full bg-beige font-dm-sans text-charcoal mb-2"
                    >
                      <span className="font-bold">{module.name}</span>
                      <div className="flex space-x-2">
                        <button
                          type="button"
                          onClick={() => handleEditModule(module.id)}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDeleteModule(module.id)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default EditProgram;
