import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosConfig';

export const useProgramModules = (programId) => {
  return useQuery({
    queryKey: ['programModules', programId],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `/v1/programs/${programId}/program_modules`
      );
      return data;
    },
    enabled: !!programId,
  });
};

export const useProgramModuleDetails = (programId, moduleId) => {
  return useQuery({
    queryKey: ['programModule', programId, moduleId],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `/v1/programs/${programId}/program_modules/${moduleId}`
      );
      return data;
    },
    enabled: !!programId && !!moduleId,
  });
};

export const useSaveModule = (programId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ moduleId, moduleData }) => {
      if (moduleId) {
        const { data } = await axiosInstance.put(
          `/v1/programs/${programId}/program_modules/${moduleId}`,
          { program_module: moduleData }
        );
        return data;
      } else {
        const { data } = await axiosInstance.post(
          `/v1/programs/${programId}/program_modules`,
          { program_module: moduleData }
        );
        return data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['programModules', programId],
      });
    },
  });
};

export const useDeleteModule = (programId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (moduleId) => {
      await axiosInstance.delete(
        `/v1/programs/${programId}/program_modules/${moduleId}`
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['programModules', programId],
      });
    },
  });
};
