import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Organizations = () => {
  return (
    <div className="flex-grow bg-cream pt-6 px-10 pb-0 min-h-screen">
      <div className="flex items-center mb-4">
        <h1 className="font-recoleta-alt text-charcoal text-3xl font-medium leading-10 mr-2">
          Organizations
        </h1>
        <NavLink
          to="/dashboard/organizations/new"
          className="flex items-center text-greenroot font-dm-sans py-2 px-4"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add Organization
        </NavLink>
      </div>
      <div className="grid grid-cols-1 gap-4">
        <OrganizationCard
          title="St. John Community Health Group"
          currentEnrolled="33 students currently enrolled"
          totalEnrolled="35 students across all time"
          certificatesIssued="2 certificates issued"
        />
        <OrganizationCard
          title="Saint Augustine Birth Center"
          currentEnrolled="10 students currently enrolled"
          totalEnrolled="102 students across all time"
          certificatesIssued="85 certificates issued"
        />
        <OrganizationCard
          title="Cigna Community Health Plan"
          currentEnrolled="22 students currently enrolled"
          totalEnrolled="22 students across all time"
          certificatesIssued="0 certificates issued"
        />
      </div>
    </div>
  );
};

const OrganizationCard = ({
  title,
  currentEnrolled,
  totalEnrolled,
  certificatesIssued,
}) => {
  return (
    <div className="bg-white border border-charcoal rounded-lg p-0">
      <div className="bg-beige border-b border-charcoal rounded-t-lg p-2 px-4">
        <h2 className="font-recoleta-alt text-charcoal text-xl font-medium leading-6 mb-0">
          {title}
        </h2>
      </div>
      <div className="pt-4 p-4">
        <div className="mb-1 font-dm-sans text-charcoal text-sm">
          {currentEnrolled}
        </div>
        <div className="mb-1 font-dm-sans text-charcoal text-sm">
          {totalEnrolled}
        </div>
        <div className="font-dm-sans text-charcoal text-sm">
          {certificatesIssued}
        </div>
      </div>
    </div>
  );
};

export default Organizations;
