import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import axiosInstance, { setAuthTokenInAxios } from '../utils/axiosConfig';
import { useAuth } from '../contexts/AuthContext';
import { useUser } from '../contexts/UserContext';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { authToken, setAuthToken } = useAuth();
  const { setUser } = useUser();

  useEffect(() => {
    if (authToken) {
      navigate('/dashboard/programs');
    }
  }, [authToken, navigate]);

  const schema = z
    .object({
      email: z.string().email({ message: 'Invalid email address' }),
      password: z.string().min(6, { message: 'Password must be at least 6 characters long' }),
      passwordConfirmation: z
        .string()
        .min(6, { message: 'Password confirmation must be at least 6 characters long' }),
    })
    .refine((data) => data.password === data.passwordConfirmation, {
      path: ['passwordConfirmation'],
      message: 'Passwords do not match',
    });

  const handleSignUp = async (e) => {
    e.preventDefault();

    const formData = {
      email,
      password,
      passwordConfirmation,
    };

    // Validate form data using Zod
    const result = schema.safeParse(formData);

    if (!result.success) {
      // Handle validation errors
      const formattedErrors = result.error.format();
      setErrors({
        email: formattedErrors.email?._errors[0],
        password: formattedErrors.password?._errors[0],
        passwordConfirmation: formattedErrors.passwordConfirmation?._errors[0],
      });
    } else {
      try {
        const response = await axiosInstance.post('/signup', {
          user: {
            email: email,
            password: password,
            password_confirmation: passwordConfirmation,
          },
        });

        if (response.data.token) {
          const { token, user } = response.data;

          localStorage.setItem('token', token);
          setAuthTokenInAxios(token);
          setAuthToken(token);
          setUser(user);

          navigate('/dashboard/programs');
        } else {
          console.log(`Error signing up: ${response}`);
        }
      } catch (error) {
        console.error('There was an error!', error);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-between min-h-screen bg-cream">
      <div></div>
      <div className="flex flex-col items-center w-full max-w-sm">
        <h1 className="mb-5 font-dm-serif-display logo-font">
          <span className="text-5xl text-orange-soda"> Maggie</span>
        </h1>
        <form
          onSubmit={handleSignUp}
          className="border border-charcoal rounded-lg px-8 pt-6 pb-8 mb-4 w-full"
        >
          <h2 className="text-3xl font-bold mb-3 text-center text-charcoal font-recoleta-alt">
            Sign Up
          </h2>
          <div className="mb-4">
            <label
              className="text-charcoal text-center font-dm-sans text-sm leading-6 uppercase"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal leading-tight"
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {errors.email && (
              <p className="text-orange-soda text-xs mt-2 font-dm-sans uppercase">{errors.email}</p>
            )}
          </div>
          <div className="mb-4">
            <label
              className="text-charcoal text-center font-dm-sans text-sm leading-6 uppercase"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal leading-tight"
              id="password"
              type="password"
              placeholder="********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            {errors.password && (
              <p className="text-orange-soda text-xs mt-2 font-dm-sans uppercase">
                {errors.password}
              </p>
            )}
          </div>
          <div className="mb-6">
            <label
              className="text-charcoal text-center font-dm-sans text-sm leading-6 uppercase"
              htmlFor="password_confirmation"
            >
              Confirm Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal leading-tight"
              id="password_confirmation"
              type="password"
              placeholder="********"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              required
            />
            {errors.passwordConfirmation && (
              <p className="text-orange-soda text-xs mt-2 font-dm-sans uppercase">
                {errors.passwordConfirmation}
              </p>
            )}
          </div>
          <div className="flex items-center justify-between">
            <button
              className="text-charcoal text-center font-dm-sans text-sm leading-6 uppercase rounded font-bold"
              type="submit"
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
      <div className="text-xs text-gray-500 flex justify-center items-center font-dm-sans py-4">
        Powered by
        <img src="/Wingwomen.png" alt="Wingwomen Logo" className="ml-1" />
      </div>
    </div>
  );
};

export default SignUp;
