import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosConfig';

// Fetch the quiz for a specific program module
export const useModuleQuiz = (programId, programModuleId) => {
  return useQuery({
    queryKey: ['moduleQuiz', programId, programModuleId],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `/v1/programs/${programId}/program_modules/${programModuleId}/module_quiz`
      );
      return data;
    },
    enabled: !!programId && !!programModuleId,
  });
};

// Save or update a module quiz
export const useSaveModuleQuiz = (programId, programModuleId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ quizData }) => {
      const { data } = await axiosInstance.put(
        `/v1/programs/${programId}/program_modules/${programModuleId}/module_quiz/upsert`,
        { module_quiz: quizData }
      );
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['moduleQuiz', programId, programModuleId],
      });
    },
  });
};

// Delete a module quiz
export const useDeleteModuleQuiz = (programId, programModuleId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      await axiosInstance.delete(
        `/v1/programs/${programId}/program_modules/${programModuleId}/module_quiz`
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['moduleQuiz', programId, programModuleId],
      });
    },
  });
};
