import React from 'react';
import { createBrowserRouter, RouterProvider, Link } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'; // Import QueryClient and QueryClientProvider
import SignUp from './SignUp';
import VerifyEmailGate from './VerifyEmailGate';
import ResetPassword from './ResetPassword';
import ResetPasswordRequest from './ResetPasswordRequest';
import EmailVerification from './EmailVerification';
import Login from './Login';
import Home from './Home';
import Dashboard from './Dashboard';
import Organizations from './Organizations';
import Programs from './Programs';
import EditProgram from './EditProgram';
import EditModule from './EditModule';
import EditQuiz from './EditQuiz';
import EditLesson from './EditLesson';
import Students from './Students';
import Exams from './Exams';
import Account from './Account';
import ProtectedRoute from './ProtectedRoute';
import { AuthProvider } from '../contexts/AuthContext';
import { UserProvider } from '../contexts/UserContext';

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    index: true,
  },
  {
    path: '/signup',
    element: <SignUp />,
  },
  {
    path: '/reset-password',
    element: <ResetPasswordRequest />,
  },
  {
    path: '/reset-password/:token',
    element: <ResetPassword />,
  },
  {
    path: '/verify-email-gate',
    element: <VerifyEmailGate />,
  },
  {
    path: '/email-verification',
    element: <EmailVerification />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/dashboard',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    children: [
      { path: 'organizations', element: <Organizations /> },
      {
        path: 'programs',
        element: <Programs />,
        children: [
          {
            path: 'edit/:programId',
            element: <EditProgram />,
            children: [
              {
                path: 'modules/edit/:moduleId',
                element: <EditModule />,
                children: [
                  { path: 'lessons/new', element: <EditLesson /> },
                  { path: 'lessons/edit/:lessonId', element: <EditLesson /> },
                  { path: 'quiz/edit', element: <EditQuiz /> },
                ],
              },
              { path: 'modules/new', element: <EditModule /> },
            ],
          },
          { path: 'new', element: <EditProgram /> },
        ],
      },
      { path: 'students', element: <Students /> },
      { path: 'exams', element: <Exams /> },
      { path: 'account', element: <Account /> },
    ],
  },
]);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <UserProvider>
          <RouterProvider router={router}>
            <nav>
              <ul>
                <li>
                  <Link to="/signup">Sign Up</Link>
                </li>
                <li>
                  <Link to="/login">Login</Link>
                </li>
                <li>
                  <Link to="/dashboard/programs">Dashboard</Link>
                </li>
              </ul>
            </nav>
          </RouterProvider>
        </UserProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
