import React from 'react';

const Students = () => {
  return (
    <div className="flex-grow bg-cream pt-6 px-10 pb-0 min-h-screen">
      <h1 className="font-recoleta-alt text-charcoal text-3xl font-medium leading-10 mb-4">
        Students
      </h1>
      <div className="grid grid-cols-1 gap-4"></div>
    </div>
  );
};

export default Students;
