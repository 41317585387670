import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosConfig';

export const usePrograms = () => {
  return useQuery({
    queryKey: ['programs'],
    queryFn: async () => {
      const { data } = await axiosInstance.get('/v1/programs');
      return data;
    },
  });
};

export const useProgramDetails = (programId) => {
  return useQuery({
    queryKey: ['program', programId],
    queryFn: async () => {
      const { data } = await axiosInstance.get(`/v1/programs/${programId}`);
      return data;
    },
    enabled: !!programId,
  });
};

export const useSaveProgram = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ programId, programData }) => {
      if (programId) {
        const { data } = await axiosInstance.put(`/v1/programs/${programId}`, {
          program: programData,
        });
        return data;
      } else {
        const { data } = await axiosInstance.post('/v1/programs', {
          program: programData,
        });
        return data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['programs'] });
    },
  });
};

export const useDeleteProgram = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (programId) => {
      await axiosInstance.delete(`/v1/programs/${programId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['programs'] });
    },
  });
};
