import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import LexicalContainer from './LexicalContainer';
import { useLesson, useSaveLesson } from './hooks/useLessons';

const EditLesson = () => {
  const [lessonName, setLessonName] = useState('');
  const [lessonContent, setLessonContent] = useState('');
  const [initialContent, setInitialContent] = useState('');
  const { programId, moduleId, lessonId } = useParams();
  const navigate = useNavigate();

  const { data: lesson, isLoading: isLoadingLessons } = useLesson(programId, moduleId, lessonId);
  const saveLessonMutation = useSaveLesson(programId, moduleId);

  useEffect(() => {
    if (lessonId && lesson) {
      setLessonName(lesson.name);
      setInitialContent(JSON.parse(lesson.content));
    }
  }, [lessonId, lesson]);

  const handleSave = async () => {
    const lessonData = { name: lessonName, content: lessonContent };

    try {
      await saveLessonMutation.mutateAsync({ lessonId, lessonData });
      navigate(`/dashboard/programs/edit/${programId}/modules/edit/${moduleId}`);
    } catch (error) {
      console.error('Failed to save the lesson:', error);
    }
  };

  const updateLessonContent = (content) => {
    setLessonContent(content);
  };

  if (isLoadingLessons) {
    return <div>Loading...</div>;
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="bg-cream min-h-full"
    >
      <div className="mb-6 px-8 border-b-2 border-charcoal">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center">
            <NavLink
              to={`/dashboard/programs/edit/${programId}/modules/edit/${moduleId}`}
              className="text-charcoal mr-2 text-xs"
            >
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            </NavLink>
            <h1 className="font-recoleta-alt text-charcoal text-3xl font-medium leading-10 ml-2">
              {lessonId ? 'Edit Lesson' : 'Create Lesson'}
            </h1>
          </div>
          <button
            type="button"
            onClick={handleSave}
            className="bg-greenroot text-beige font-bold py-2 px-4 rounded-full font-dm-sans text-sm"
          >
            <FontAwesomeIcon icon={faSave} className="mr-2" />
            Save
          </button>
        </div>
      </div>
      <div className="px-8 h-full">
        <div className="relative mb-4">
          <input
            type="text"
            id="lesson_name"
            value={lessonName}
            onChange={(e) => setLessonName(e.target.value)}
            className="block px-3 py-3 w-full text-sm bg-cream rounded-lg border border-charcoal appearance-none dark:text-white focus:outline-none focus:ring-0 focus:border-blue-500 peer text-charcoal font-dm-sans"
            placeholder="Lesson Name"
          />
        </div>
        <div className="relative mb-4 h-full">
          <LexicalContainer initialContent={initialContent} onSave={updateLessonContent} />
        </div>
      </div>
    </form>
  );
};

export default EditLesson;
