import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import axiosInstance, { setAuthTokenInAxios } from '../utils/axiosConfig';
import { useAuth } from '../contexts/AuthContext';
import { useUser } from '../contexts/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faUserGraduate,
  faUniversity,
  faBalanceScale,
  faUserCircle,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
  const navigate = useNavigate();
  const { setAuthToken } = useAuth();
  const { user, setUser } = useUser();

  const handleLogout = async () => {
    try {
      const response = await axiosInstance.delete('/logout', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      });
      if (response.status === 200 || response.status === 204) {
        // Clear token from local storage and context
        localStorage.removeItem('token');
        setAuthToken(null);
        setAuthTokenInAxios(null);
        setUser(null);

        // Redirect to login page
        navigate('/login');
      }
    } catch (error) {
      console.error('Logout failed:', error.response || error);
    }
  };

  return (
    <div className="bg-greenroot text-cream w-16 sm:min-w-[220px] py-7 px-2 flex flex-col justify-between min-h-screen">
      <div>
        <h1 className="text-cream pl-2 font-semibold text-3xl font-dm-serif-display logo-font mb-4 hidden sm:block">
          Maggie
        </h1>
        <nav>
          <NavLink
            to="/dashboard/organizations"
            className={({ isActive }) =>
              `flex items-center mb-2 py-3 px-4 rounded transition duration-200 ${
                isActive
                  ? 'bg-cream text-charcoal'
                  : 'hover:bg-cream hover:text-charcoal text-cream'
              } font-dm-sans text-base leading-6 text-normal font-normal`
            }
          >
            <FontAwesomeIcon icon={faUniversity} className="mr-2" />
            <span className="hidden sm:block">Organizations</span>
          </NavLink>
          <NavLink
            to="/dashboard/programs"
            className={({ isActive }) =>
              `flex items-center mb-2 py-3 px-4 rounded transition duration-200 ${
                isActive
                  ? 'bg-cream text-charcoal'
                  : 'hover:bg-cream hover:text-charcoal text-cream'
              } font-dm-sans text-base leading-6 text-normal font-normal`
            }
          >
            <FontAwesomeIcon icon={faBook} className="mr-2" />
            <span className="hidden sm:block">Programs</span>
          </NavLink>
          <NavLink
            to="/dashboard/students"
            className={({ isActive }) =>
              `flex items-center mb-2 py-3 px-4 rounded transition duration-200 ${
                isActive
                  ? 'bg-cream text-charcoal'
                  : 'hover:bg-cream hover:text-charcoal text-cream'
              } font-dm-sans text-base leading-6 text-normal font-normal`
            }
          >
            <FontAwesomeIcon icon={faUserGraduate} className="mr-2" />
            <span className="hidden sm:block">Students</span>
          </NavLink>
          <NavLink
            to="/dashboard/exams"
            className={({ isActive }) =>
              `flex items-center mb-2 py-3 px-4 rounded transition duration-200 ${
                isActive
                  ? 'bg-cream text-charcoal'
                  : 'hover:bg-cream hover:text-charcoal text-cream'
              } font-dm-sans text-base leading-6 text-normal font-normal`
            }
          >
            <FontAwesomeIcon icon={faBalanceScale} className="mr-2" />
            <span className="hidden sm:block">Final Exams</span>
          </NavLink>
        </nav>
      </div>
      <div>
        <NavLink
          to="/dashboard/account"
          className="flex items-center block py-3 px-4 rounded transition duration-200 text-cream font-dm-sans text-base leading-6 text-normal font-normal mb-2"
        >
          <FontAwesomeIcon icon={faUserCircle} className="mr-2" />
          <span className="hidden sm:block">
            Account [{user?.role === 'admin' ? 'ADMIN' : 'STUDENT'}]
          </span>
        </NavLink>
        <button
          onClick={handleLogout}
          className="flex items-center block py-3 px-4 rounded transition duration-200 text-cream font-dm-sans text-base leading-6 text-normal font-normal"
        >
          <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
          <span className="hidden sm:block">Logout</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
