import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosConfig';

export const useLessons = (programId, moduleId) => {
  return useQuery({
    queryKey: ['lessons', programId, moduleId],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `/v1/programs/${programId}/program_modules/${moduleId}/lessons`
      );
      return data;
    },
    enabled: !!programId && !!moduleId,
  });
};

export const useLesson = (programId, moduleId, lessonId) => {
  return useQuery({
    queryKey: ['lesson', programId, moduleId, lessonId],
    queryFn: async () => {
      const { data } = await axiosInstance.get(
        `/v1/programs/${programId}/program_modules/${moduleId}/lessons/${lessonId}`
      );
      return data;
    },
    enabled: !!programId && !!moduleId && !!lessonId,
  });
};

export const useSaveLesson = (programId, moduleId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ lessonId, lessonData }) => {
      if (lessonId) {
        const { data } = await axiosInstance.put(
          `/v1/programs/${programId}/program_modules/${moduleId}/lessons/${lessonId}`,
          { lesson: lessonData }
        );
        return data;
      } else {
        const { data } = await axiosInstance.post(
          `/v1/programs/${programId}/program_modules/${moduleId}/lessons`,
          { lesson: lessonData }
        );
        return data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lessons', programId, moduleId],
      });
    },
  });
};

export const useDeleteLesson = (programId, moduleId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (lessonId) => {
      await axiosInstance.delete(
        `/v1/programs/${programId}/program_modules/${moduleId}/lessons/${lessonId}`
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lessons', programId, moduleId],
      });
    },
  });
};
