import React, { useState, useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  // $createTextNode,
  $getRoot,
  $isParagraphNode,
  CLEAR_EDITOR_COMMAND,
  // CLEAR_HISTORY_COMMAND,
  // COMMAND_PRIORITY_EDITOR,
} from 'lexical';
import { exportFile, importFile } from '@lexical/file';

// import { useModal } from '../hooks/useModal';

export default function ActionsPlugin() {
  const [editor] = useLexicalComposerContext();
  const [isEditorEmpty, setIsEditorEmpty] = useState(true);
  // const [modal, showModal] = useModal();

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.getEditorState().read(() => {
        const root = $getRoot();
        const children = root.getChildren();
        if (children.length > 1) {
          setIsEditorEmpty(false);
        } else {
          if ($isParagraphNode(children[0])) {
            const paragraphChildren = children[0].getChildren();
            setIsEditorEmpty(paragraphChildren.length === 0);
          } else {
            setIsEditorEmpty(false);
          }
        }
      });
    });
  }, [editor]);

  return (
    <div className="actions">
      <button
        type="button"
        className="action-button import"
        onClick={() => importFile(editor)}
        title="Import"
        aria-label="Import editor state from JSON"
      >
        <i className="import" />
      </button>
      <button
        type="button"
        className="action-button export"
        onClick={() =>
          exportFile(editor, {
            fileName: `MaggieExport-${new Date().toISOString()}`,
            source: 'Maggie',
          })
        }
        title="Export"
        aria-label="Export editor state to JSON"
      >
        <i className="export" />
      </button>
      <button
        type="button"
        className="action-button clear"
        disabled={isEditorEmpty}
        onClick={() => {
          // showModal('Clear editor', (onClose) => (
          //   <ShowClearDialog editor={editor} onClose={onClose} />
          // ));
        }}
        title="Clear"
        aria-label="Clear editor contents"
      >
        <i className="clear" />
      </button>
      {/* {modal} */}
    </div>
  );
}

function ShowClearDialog({ editor, onClose }) {
  return (
    <>
      Are you sure you want to clear the editor?
      <div className="Modal__content">
        <Button
          type="button"
          onClick={() => {
            editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
            editor.focus();
            onClose();
          }}
        >
          Clear
        </Button>{' '}
        <Button
          type="button"
          onClick={() => {
            editor.focus();
            onClose();
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
}
