import { DecoratorNode } from 'lexical';
import React, { Suspense, useRef, useState, useCallback } from 'react';
import ImageComponent from '../ImageComponent';

function ImageNodeComponent({ node, editor, width, height }) {
  const imageRef = useRef(null);

  return (
    <Suspense fallback={null}>
      <div className="editor-image">
        <ImageComponent
          src={node.getSrc()}
          altText={node.getAltText()}
          imageRef={imageRef}
          nodeKey={node.getKey()}
          editor={editor}
          width={width}
          height={height}
        />
      </div>
    </Suspense>
  );
}

export class ImageNode extends DecoratorNode {
  constructor(src, altText, width = 'auto', height = 'auto', key) {
    super(key);
    this.__src = src;
    this.__altText = altText;
    this.__width = width;
    this.__height = height;
  }

  static getType() {
    return 'image';
  }

  static clone(node) {
    return new ImageNode(
      node.__src,
      node.__altText,
      node.__width,
      node.__height,
      node.__key
    );
  }

  static importJSON(serializedNode) {
    const { src, altText, width, height } = serializedNode;
    return $createImageNode({ src, altText, width, height });
  }

  exportJSON() {
    return {
      src: this.__src,
      altText: this.__altText,
      width: this.__width,
      height: this.__height,
      type: 'image',
      version: 1,
    };
  }

  createDOM() {
    const div = document.createElement('div');
    div.className = 'editor-image';
    return div;
  }

  updateDOM() {
    return false;
  }

  getSrc() {
    return this.__src;
  }

  getAltText() {
    return this.__altText;
  }

  setWidthAndHeight(width, height) {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  decorate(editor) {
    return (
      <ImageNodeComponent
        node={this}
        editor={editor}
        width={this.__width}
        height={this.__height}
      />
    );
  }
}

export function $createImageNode({ src, altText, width, height }) {
  return new ImageNode(src, altText, width, height);
}

export function $isImageNode(node) {
  return node instanceof ImageNode;
}
