import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { useModuleQuiz, useSaveModuleQuiz } from './hooks/useModuleQuiz';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faChevronLeft, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const EditQuiz = () => {
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();
  const { programId, moduleId } = useParams();

  const { data: quiz, isSuccess: isQuizLoaded } = useModuleQuiz(programId, moduleId);
  const saveQuizMutation = useSaveModuleQuiz(programId, moduleId);

  useEffect(() => {
    if (isQuizLoaded && quiz) {
      setQuestions(
        quiz.questions?.length > 0
          ? quiz.questions
          : [{ text: '', answers: [{ text: '', correct: false }] }]
      );
    } else {
      setQuestions([{ text: '', answers: [{ text: '', correct: false }] }]);
    }
  }, [quiz, isQuizLoaded]);

  const handleAddQuestion = () => {
    setQuestions([...questions, { text: '', answers: [{ text: '', correct: false }] }]);
  };

  const handleDeleteQuestion = (index) => {
    setQuestions(questions.filter((_, qIndex) => qIndex !== index));
  };

  const handleQuestionChange = (index, text) => {
    const newQuestions = [...questions];
    newQuestions[index].text = text;
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (qIndex, aIndex, text) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answers[aIndex].text = text;
    setQuestions(newQuestions);
  };

  const handleCorrectAnswerChange = (qIndex, aIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answers.forEach((answer, i) => {
      answer.correct = i === aIndex;
    });
    setQuestions(newQuestions);
  };

  const handleAddAnswer = (qIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answers.push({ text: '', correct: false });
    setQuestions(newQuestions);
  };

  const handleDeleteAnswer = (qIndex, aIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].answers = newQuestions[qIndex].answers.filter(
      (_, index) => index !== aIndex
    );
    setQuestions(newQuestions);
  };

  const handleSaveQuiz = async () => {
    try {
      await saveQuizMutation.mutateAsync({
        quizData: { questions },
      });
      navigate(`/dashboard/programs/edit/${programId}/modules/edit/${moduleId}`);
    } catch (error) {
      console.error('Failed to save quiz:', error);
    }
  };

  return (
    <div className="bg-cream min-h-full">
      <div className="mb-3 px-8 border-b-2 border-charcoal">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center">
            <NavLink
              to={`/dashboard/programs/edit/${programId}/modules/edit/${moduleId}`}
              className="text-charcoal mr-2 text-xs"
            >
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            </NavLink>
            <h1 className="font-recoleta-alt text-charcoal text-3xl font-medium leading-10 ml-2">
              Edit Module Quiz
            </h1>
          </div>
          <div className="flex space-x-4">
            <button
              onClick={handleAddQuestion}
              className="bg-greenroot text-beige font-bold py-2 px-4 rounded-full font-dm-sans text-sm flex items-center"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Question
            </button>
            <button
              onClick={handleSaveQuiz}
              className="bg-greenroot text-beige font-bold py-2 px-4 rounded-full font-dm-sans text-sm flex items-center"
            >
              <FontAwesomeIcon icon={faSave} className="mr-2" />
              Save
            </button>
          </div>
        </div>
      </div>

      <div className="px-8 h-full mb-7">
        <ul className="list-disc pl-5 text-charcoal font-dm-sans text-base">
          <li>Quizzes must have at least 5 questions</li>
          <li>
            If more than 5 questions exist, five are randomly picked from all available questions
            when students take the quiz
          </li>
          <li>Students must get 4 of the 5 questions correct in order to pass</li>
        </ul>
      </div>

      <div className="px-8 h-full">
        {questions.map((question, qIndex) => (
          <div key={qIndex} className="mb-6 p-4 pt-6 pb-2 border border-charcoal rounded-lg">
            <div className="relative mb-1 flex items-center">
              <textarea
                id={`question-${qIndex}`}
                value={question.text}
                onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
                className="min-h-11 resize-y block px-3 py-3 w-full text-sm bg-cream rounded-lg border border-charcoal appearance-none dark:text-white focus:outline-none focus:ring-0 focus:border-blue-500 peer text-charcoal font-dm-sans"
                placeholder=" "
              />
              <label
                htmlFor={`question-${qIndex}`}
                className="absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] bg-cream px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-6 text-charcoal font-dm-sans left-2"
              >
                Question {qIndex + 1}
              </label>
              <button
                type="button"
                onClick={() => handleDeleteQuestion(qIndex)}
                className="text-red-500 ml-2"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
            <div className="flex items-center mb-2">
              <span className="text-sm text-charcoal font-dm-sans">{`${question.answers.length} ANSWER CHOICES`}</span>
              <button
                type="button"
                onClick={() => handleAddAnswer(qIndex)}
                className="text-greenroot font-dm-sans py-2 px-4 bg-transparent flex items-center text-sm"
              >
                <FontAwesomeIcon icon={faPlus} className="mr-1" />
                Add new answer choice
              </button>
            </div>
            {question.answers.map((answer, aIndex) => (
              <div key={aIndex} className="relative mb-4">
                <div className="flex items-center">
                  <input
                    type="radio"
                    name={`correct-answer-${qIndex}`}
                    checked={answer.correct}
                    onChange={() => handleCorrectAnswerChange(qIndex, aIndex)}
                    className="mr-2"
                  />
                  <input
                    type="text"
                    id={`answer-${qIndex}-${aIndex}`}
                    value={answer.text}
                    onChange={(e) => handleAnswerChange(qIndex, aIndex, e.target.value)}
                    className="block px-3 py-3 w-full text-sm bg-cream rounded-lg border border-charcoal appearance-none dark:text-white focus:outline-none focus:ring-0 focus:border-blue-500 peer text-charcoal font-dm-sans"
                    placeholder=" "
                  />
                  <label
                    htmlFor={`answer-${qIndex}-${aIndex}`}
                    className="absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] bg-cream px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-6 text-charcoal font-dm-sans left-7"
                  >
                    Answer {aIndex + 1}
                  </label>
                  <button
                    type="button"
                    onClick={() => handleDeleteAnswer(qIndex, aIndex)}
                    className="text-red-500 ml-2"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EditQuiz;
