import React, { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { useLexicalEditable } from '@lexical/react/useLexicalEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { TRANSFORMERS } from '@lexical/markdown';

import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import TreeViewPlugin from './plugins/TreeViewPlugin';
import ActionsPlugin from './plugins/ActionsPlugin';
import ImagesPlugin from './plugins/ImagesPlugin';

function Placeholder() {
  return <div className="editor-placeholder">Enter some rich text...</div>;
}

export default function LexicalEditor({ initialContent, onSave }) {
  const isEditable = useLexicalEditable();
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (initialContent) {
      const parsedEditorState = editor.parseEditorState(initialContent);
      Promise.resolve().then(() => {
        editor.update(() => {
          editor.setEditorState(parsedEditorState);
        });
      });
    }
  }, [initialContent, editor]);

  const handleEditorChange = (editorState) => {
    const contentJSON = JSON.stringify(editorState.toJSON());
    onSave(contentJSON);
  };

  return (
    <div className="editor">
      <ToolbarPlugin />
      <div className="editor-inner">
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          placeholder={<Placeholder />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <AutoFocusPlugin />
        <CodeHighlightPlugin />
        <ListPlugin />
        <LinkPlugin />
        <ImagesPlugin />
        <ClickableLinkPlugin disabled={isEditable} />
        <AutoLinkPlugin />
        <ListMaxIndentLevelPlugin maxDepth={7} />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
      </div>
      <ActionsPlugin
        isRichText={true}
        shouldPreserveNewLinesInMarkdown={true}
      />
      <TreeViewPlugin />
      <OnChangePlugin onChange={handleEditorChange} />
    </div>
  );
}
