import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useUser } from '../contexts/UserContext';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useUser();
  const { authToken } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!authToken || !user) {
    return <Navigate to="/login" replace />;
  }

  if (!user.email_verified) {
    return <Navigate to="/verify-email-gate" replace />;
  }

  return children;
};

export default ProtectedRoute;
