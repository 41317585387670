import React, { createContext, useContext, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../utils/axiosConfig';
import { useAuth } from './AuthContext';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { authToken, setAuthToken } = useAuth();
  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    try {
      const response = await axiosInstance.get('/v1/user', {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      });
      setUser(response.data); // Set user data directly
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setAuthToken(null);
      }
      throw error;
    }
  };

  useQuery({
    queryKey: ['user', authToken],
    queryFn: fetchUser,
    enabled: !!authToken,
    retry: false,
    onSuccess: (data) => setUser(data),
  });

  return (
    <UserContext.Provider value={{ user, setUser, loading: !user && !!authToken, fetchUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
