import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import Countdown from 'react-countdown';

const EmailVerification = () => {
  const [statusMessage, setStatusMessage] = useState('');
  const [showCountdown, setShowCountdown] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const apiCallMade = useRef(false); // Track if the API call has been made

  useEffect(() => {
    if (apiCallMade.current) return; // Prevent further API calls

    const verifyEmail = async () => {
      const token = searchParams.get('token');

      try {
        const response = await axiosInstance.post('/users/email_verifications/verify', { token });
        let message = response.data.message;

        if (response.data.success || message.includes('already verified')) {
          setStatusMessage(message);
          setShowCountdown(true);

          setTimeout(() => {
            navigate('/dashboard/programs', { replace: true });
          }, 3000);
        } else {
          setStatusMessage(message);
        }

        apiCallMade.current = true;
      } catch (error) {
        setStatusMessage(error.response?.data?.message || 'Verification failed. Please try again.');
        apiCallMade.current = true;
      }
    };

    verifyEmail();
  }, [searchParams, navigate]);

  const countdownRenderer = ({ seconds, completed }) => {
    if (completed) {
      navigate('/dashboard/programs', { replace: true });
      return null;
    } else {
      return (
        <span className="font-dm-sans text-charcoal text-xl">Redirecting in {seconds}...</span>
      );
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 w-full">
      <div className="w-full h-full min-h-screen p-12 bg-cream text-center flex flex-col justify-between">
        <div>
          <h1 className="font-dm-serif-display text-4xl mb-6 text-orange-soda">Maggie</h1>
          <img src="/confirm.svg" alt="Confirmation Icon" className="w-30 h-30 mb-4 mx-auto" />
          <p className="font-dm-sans text-charcoal text-xl">{statusMessage}</p>
          {showCountdown && <Countdown date={Date.now() + 3000} renderer={countdownRenderer} />}
        </div>
        <div className="text-xs text-gray-500 mt-4 flex justify-center items-center font-dm-sans">
          Powered by
          <img src="/Wingwomen.png" alt="Wingwomen Logo" className="ml-1" />
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
