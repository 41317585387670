import React, { useState, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import axiosInstance, { setAuthTokenInAxios } from '../utils/axiosConfig';
import { useAuth } from '../contexts/AuthContext';
import { useUser } from '../contexts/UserContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { authToken, setAuthToken } = useAuth();
  const { user, setUser } = useUser();

  useEffect(() => {
    if (authToken && user) {
      navigate('/dashboard/programs', { replace: true });
    }
  }, [authToken, user, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/login', {
        user: {
          email: email,
          password: password,
        },
      });

      const { token, user } = response.data;

      if (token) {
        setAuthToken(token);
        setAuthTokenInAxios(token);
        setUser(user);

        navigate('/dashboard/programs', { replace: true });
      } else {
        console.error('Login failed: No token received.');
      }
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-between min-h-screen bg-cream">
      <div></div>
      <div className="flex flex-col items-center w-full max-w-sm">
        <h1 className="mb-5 font-dm-serif-display logo-font">
          <span className="text-5xl text-orange-soda"> Maggie</span>
        </h1>
        <form
          onSubmit={handleLogin}
          className="border border-charcoal rounded-lg px-8 pt-6 pb-8 mb-4 w-full"
        >
          <h2 className="text-3xl font-bold mb-3 text-center text-charcoal font-recoleta-alt">
            Login
          </h2>
          <div className="mb-4">
            <label
              className="text-charcoal text-center font-dm-sans text-sm leading-6 uppercase"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal leading-tight"
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-6">
            <label
              className="text-charcoal text-center font-dm-sans text-sm leading-6 uppercase"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal mb-3 leading-tight"
              id="password"
              type="password"
              placeholder="********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="text-charcoal text-center font-dm-sans text-sm leading-6 uppercase rounded font-bold"
              type="submit"
            >
              Login
            </button>
            <NavLink
              to="/reset-password"
              className="text-charcoal text-center font-dm-sans text-sm leading-6 uppercase rounded font-bold"
            >
              Forgot Password?
            </NavLink>
          </div>
        </form>
      </div>
      <div className="text-xs text-gray-500 flex justify-center items-center font-dm-sans py-4">
        Powered by
        <img src="/Wingwomen.png" alt="Wingwomen Logo" className="ml-1" />
      </div>
    </div>
  );
};

export default Login;
