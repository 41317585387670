import axios from 'axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

const baseURL = process.env.MAGGIE_API_URL || 'http://localhost:3000/api';

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const setAuthTokenInAxios = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Token ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

export default axiosInstance;
