import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Home = () => {
  const navigate = useNavigate();
  const { authToken, setAuthToken } = useAuth();

  useEffect(() => {
    if (authToken) {
      navigate('/dashboard/programs', { replace: true });
    }
  }, [authToken, navigate]);

  return (
    <div className="flex flex-col min-h-screen bg-cream">
      <div className="flex-grow flex flex-col justify-center items-center">
        <div className="max-w-md w-full text-center">
          <h1 className="mb-4 font-recoleta-alt logo-font">
            <span className="text-3xl font-bold text-charcoal">Welcome to</span>
            <span className="text-3xl font-bold text-orange-soda"> Maggie</span>
          </h1>
          <div className="flex justify-center space-x-4">
            <Link
              to="/login"
              className="px-4 py-2 bg-greenroot text-cream font-dm-sans rounded-full w-133"
            >
              Login
            </Link>
            <Link
              to="/signup"
              className="px-4 py-2 bg-greenroot text-cream font-dm-sans rounded-full w-133"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </div>
      <div className="text-xs text-gray-500 flex justify-center items-center font-dm-sans py-4">
        Powered by
        <img src="/Wingwomen.png" alt="Wingwomen Logo" className="ml-1" />
      </div>
    </div>
  );
};

export default Home;
