import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useUser } from '../contexts/UserContext';

const VerifyEmailGate = () => {
  const { authToken } = useAuth();
  const { user, loading } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;

    if (!authToken) {
      navigate('/login', { replace: true });
    } else if (user?.email_verified) {
      navigate('/dashboard/programs', { replace: true });
    }
  }, [authToken, user, loading, navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">Loading...</div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-between min-h-screen bg-cream">
      <div></div>
      <div className="flex flex-col items-center w-full max-w-sm">
        <h1 className="mb-5 font-dm-serif-display logo-font">
          <span className="text-5xl text-orange-soda"> Maggie</span>
        </h1>
        <div className="border border-charcoal rounded-lg px-8 pt-6 pb-8 mb-4">
          <h2 className="text-3xl font-bold mb-3 text-center text-charcoal font-recoleta-alt">
            Verify Your Email
          </h2>
          <p className="text-center text-charcoal font-dm-sans">
            Please verify your email address to continue. Check your inbox for a verification email.
          </p>
        </div>
      </div>
      <div className="text-xs text-gray-500 flex justify-center items-center font-dm-sans py-4">
        Powered by
        <img src="/Wingwomen.png" alt="Wingwomen Logo" className="ml-1" />
      </div>
    </div>
  );
};

export default VerifyEmailGate;
