import React, { useState } from 'react';
import axiosInstance from '../utils/axiosConfig';

const ResetPasswordRequest = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await axiosInstance.post('/password', { user: { email } });
      setMessage('Password reset instructions have been sent to your email.');
    } catch (error) {
      setMessage('Error sending password reset instructions.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-between min-h-screen bg-cream">
      <div></div>
      <div className="flex flex-col items-center w-full max-w-sm">
        <h1 className="mb-5 font-dm-serif-display logo-font">
          <span className="text-5xl text-orange-soda"> Maggie</span>
        </h1>
        <div className="border border-charcoal rounded-lg px-8 pt-6 pb-8 mb-4 w-full">
          <h2 className="text-3xl font-bold mb-3 text-center text-charcoal font-recoleta-alt">
            Password Reset
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="text-charcoal text-center font-dm-sans text-sm leading-6 uppercase">
                Email:
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-charcoal leading-tight"
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="text-charcoal text-center font-dm-sans text-sm leading-6 uppercase rounded font-bold"
              >
                Send Instructions
              </button>
            </div>
          </form>
          {message && <p className="text-center mt-4 text-greenroot font-dm-sans">{message}</p>}
        </div>
      </div>
      <div className="text-xs text-gray-500 flex justify-center items-center font-dm-sans py-4">
        Powered by
        <img src="/Wingwomen.png" alt="Wingwomen Logo" className="ml-1" />
      </div>
    </div>
  );
};

export default ResetPasswordRequest;
