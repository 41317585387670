import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, NavLink, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faPlus,
  faPencilAlt,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  useProgramModuleDetails,
  useSaveModule,
} from './hooks/useProgramModules';
import { useLessons, useDeleteLesson } from './hooks/useLessons';

const EditModule = () => {
  const [moduleName, setModuleName] = useState('');
  const [moduleDescription, setModuleDescription] = useState('');
  const navigate = useNavigate();
  const { programId, moduleId } = useParams();

  const { data: module, isSuccess: isModuleLoaded } = useProgramModuleDetails(
    programId,
    moduleId
  );
  const { data: lessons = [], isLoading: isLessonsLoading } = useLessons(
    programId,
    moduleId
  );

  const saveModuleMutation = useSaveModule(programId);
  const deleteLessonMutation = useDeleteLesson(programId, moduleId);

  const moduleDescriptionRef = useRef(null);

  const isEditingLesson =
    location.pathname.includes('lessons/edit') ||
    location.pathname.includes('lessons/new');

  const isEditingQuiz = location.pathname.includes('quiz/edit');

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight(moduleDescriptionRef.current);
  }, [moduleDescription]);

  useEffect(() => {
    if (isModuleLoaded && module) {
      setModuleName(module.name);
      setModuleDescription(module.description);
    }
  }, [module, isModuleLoaded]);

  const handleSave = async (event) => {
    event.preventDefault();

    try {
      const moduleData = {
        name: moduleName,
        description: moduleDescription,
      };

      await saveModuleMutation.mutateAsync({
        moduleId,
        moduleData,
      });

      navigate(`/dashboard/programs/edit/${programId}`);
    } catch (error) {
      console.error('Error saving module:', error);
    }
  };

  const handleCreateLesson = () => {
    navigate(
      `/dashboard/programs/edit/${programId}/modules/edit/${moduleId}/lessons/new`
    );
  };

  const handleEditLesson = (lessonId) => {
    navigate(
      `/dashboard/programs/edit/${programId}/modules/edit/${moduleId}/lessons/edit/${lessonId}`
    );
  };

  const handleEditQuiz = () => {
    navigate(
      `/dashboard/programs/edit/${programId}/modules/edit/${moduleId}/quiz/edit`
    );
  };

  const handleDeleteLesson = async (lessonId) => {
    try {
      await deleteLessonMutation.mutateAsync(lessonId);
    } catch (error) {
      console.error('Error deleting lesson:', error);
    }
  };

  if (isEditingLesson || isEditingQuiz) {
    return <Outlet />;
  }

  return (
    <form onSubmit={handleSave} className="bg-cream min-h-full">
      <div>
        <div className="mb-6 px-8 border-b-2 border-charcoal">
          <div className="flex justify-between items-center py-4">
            <div className="flex items-center">
              <NavLink
                to={`/dashboard/programs/edit/${programId}`}
                className="text-charcoal mr-2 text-xs"
              >
                <FontAwesomeIcon icon={faChevronLeft} size="lg" />
              </NavLink>
              <h1 className="font-recoleta-alt text-charcoal text-3xl font-medium leading-10 ml-2">
                {moduleId ? 'Edit Module' : 'Create Module'}
              </h1>
            </div>
            <button
              type="submit"
              className="bg-greenroot text-beige font-bold py-2 px-4 rounded-full font-dm-sans text-sm w-133"
            >
              Save
            </button>
          </div>
        </div>
        <div className="px-8">
          <div className="relative mb-4">
            <input
              type="text"
              id="module_name"
              value={moduleName}
              onChange={(e) => setModuleName(e.target.value)}
              className="block px-3 py-3 w-full text-sm bg-cream rounded-lg border border-charcoal appearance-none dark:text-white focus:outline-none focus:ring-0 focus:border-blue-500 peer text-charcoal font-dm-sans"
              placeholder=" "
            />
            <label
              htmlFor="module_name"
              className="absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] bg-cream px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-6 text-charcoal font-dm-sans left-2"
            >
              Module Name
            </label>
          </div>
          <div className="relative mb-4">
            <textarea
              id="module_description"
              value={moduleDescription}
              onChange={(e) => {
                setModuleDescription(e.target.value);
                adjustTextareaHeight(e.target);
              }}
              className="min-h-11 resize-y block px-3 py-3 w-full text-sm bg-cream rounded-lg border border-charcoal appearance-none dark:text-white focus:outline-none focus:ring-0 focus:border-blue-500 peer text-charcoal font-dm-sans"
              placeholder=" "
              rows="1"
              ref={moduleDescriptionRef}
            ></textarea>
            <label
              htmlFor="module_description"
              className="absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 z-10 origin-[0] bg-cream px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-3 peer-focus:scale-75 peer-focus:-translate-y-6 text-charcoal font-dm-sans left-2"
            >
              Module Description
            </label>
          </div>

          {isLessonsLoading ? (
            <div>Loading lessons...</div>
          ) : (
            <div className="mt-4">
              <div className="flex justify-between items-center">
                <h2 className="text-charcoal text-center font-dm-sans text-sm font-bold leading-6 font-bold uppercase">
                  {lessons.length} Lessons
                </h2>
                {moduleId && (
                  <div className="flex">
                    <NavLink
                      to={`/dashboard/programs/edit/${programId}/modules/edit/${moduleId}/quiz/edit`}
                      className="flex items-center text-greenroot font-dm-sans py-2 px-4 bg-transparent"
                    >
                      <FontAwesomeIcon icon={faPencilAlt} className="mr-1" />
                      Edit Quiz
                    </NavLink>
                    <button
                      type="button"
                      onClick={handleCreateLesson}
                      className="flex items-center text-greenroot font-dm-sans py-2 px-4 bg-transparent"
                    >
                      <FontAwesomeIcon icon={faPlus} className="mr-1" />
                      Create lesson
                    </button>
                  </div>
                )}
              </div>
              {lessons.length === 0 ? (
                <div className="flex flex-col justify-center items-center gap-4 p-6 border border-charcoal rounded-lg bg-beige w-full text-charcoal text-lg font-recoleta-alt">
                  No lessons in this module yet
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center w-full">
                  {lessons.map((lesson) => (
                    <div
                      key={lesson.id}
                      className="flex justify-between items-center w-full gap-4 px-6 py-4 border-4 border-charcoal rounded-full bg-beige font-dm-sans text-charcoal mb-2"
                    >
                      <span className="font-bold">{lesson.name}</span>
                      <div className="flex space-x-2">
                        <button
                          type="button"
                          onClick={() => handleEditLesson(lesson.id)}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </button>
                        <button
                          type="button"
                          onClick={() => handleDeleteLesson(lesson.id)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default EditModule;
